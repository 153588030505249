import React from "react"
import styled from "styled-components"
import Img from "gatsby-image"
import Spacer from "../../PC/atoms/Spacer"
import RecruitFigure1 from "../../../images/mobile/recruit-figure1.svg"
import RecruitFigure2 from "../../../images/mobile/recruit-figure2.svg"
import RecruitFigure3 from "../../../images/mobile/recruit-figure3.svg"
import RecruitFigure4 from "../../../images/mobile/recruit-figure4.svg"
import RecruitFigure5 from "../../../images/mobile/recruit-figure5.svg"
import FluidImages from "../../Images/FluidImages"
import { useBreakpoint } from "gatsby-plugin-breakpoints/BreakpointProvider"

const Outer = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    height: auto;
    align-items: center;
`

const Inner = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 90%;
`

const Title = styled.h2`
    font-size: 28px;
    font-weight: 700;
    letter-spacing: 4px;
    line-height: 35px;
    margin: 50px 0px;
`

const TitleWide = styled.h2`
    font-size: 35px;
    font-weight: 700;
    letter-spacing: 4px;
    line-height: 35px;
    margin: 50px 0px;
`

const TopSentence = styled.p`
    margin: 5px 0px;
    letter-spacing: 1px;
    line-height: 18px;
    width: 85%;
    min-width: 280px;
`

const Explanation = styled.p`
    line-height: 28px;
    letter-spacing: 1px;
    width: 85%;
    min-width: 280px;
`

const FigureExplanationTopOuter = styled.div`
    width: 100%;
    margin-bottom: 30px;
`

const FigureExplanationTopInner = styled.div`
    width: 40%;
    margin: 0 auto;
`

const FigureExplanations = styled.div`
    display: flex;
    width: 100%;
`

const FigureExplanation = styled.div`
    width: 40%;
    margin: 0px auto 30px auto ;
`

const FigureSentences = styled.p`
    font-size: 13px;
    line-height: 17px;
`

const FigureImg = styled.img`
    display: block;
    margin: 0 auto 8px auto;
`

const FigureImgWide = styled.img`
    display: block;
    margin: 15px auto;
`


const MobileRecruitTop = () => {

    const breakPoints = useBreakpoint()

    return (
        <Outer>
            <Inner>
                {breakPoints.mobileWide
                    ?
                        <TitleWide>UNLEASH THE  POWER  OF YOU</TitleWide>
                    :
                        <Title>UNLEASH<br /> THE  POWER  OF<br /> YOU</Title>
                }
                <TopSentence>我々は特定の型にはめ込むような人材教育は行いません。ひとりひとりが持つ能力や個性は千差万別。</TopSentence>
                <TopSentence>個々人が持つポテンシャルを開放し、自分らしく価値を発揮できる人が育つような環境を提供します。</TopSentence>
                <Spacer height="50px" />
                <FigureExplanationTopOuter>
                    <FigureExplanationTopInner>
                        {breakPoints.mobileWide
                            ?
                                <FigureImgWide src={RecruitFigure1} />
                            :
                                <FigureImg src={RecruitFigure1} />
                        }
                        <FigureSentences>ReactやRuby on Railsを用いて様々なシステムの基盤やハイブリッドアプリの開発。</FigureSentences>
                    </FigureExplanationTopInner>
                </FigureExplanationTopOuter>
                <FigureExplanations>
                    <FigureExplanation>
                        {breakPoints.mobileWide
                            ?
                                <FigureImgWide src={RecruitFigure2} />
                            :
                                <FigureImg src={RecruitFigure2} />
                        }
                        <FigureSentences>RDBMSだけでなく、MongoDBやNeo4jにため込んだ大量のデータから、サービスの競争力を高める情報を導出。</FigureSentences>
                    </FigureExplanation>
                    <FigureExplanation>
                        {breakPoints.mobileWide
                            ?
                                <FigureImgWide src={RecruitFigure3} />
                            :
                                <FigureImg src={RecruitFigure3} />
                        }
                        <FigureSentences>UnityやBlenderによる3D技術やリアルタイム通信技術を組み合わせてアプリケーション開発。</FigureSentences>
                    </FigureExplanation>
                </FigureExplanations>
                <FigureExplanations>
                    <FigureExplanation>
                        {breakPoints.mobileWide
                            ?
                                <FigureImgWide src={RecruitFigure4} />
                            :
                                <FigureImg src={RecruitFigure4} />
                        }
                        <FigureSentences>最新のアルゴリズムを実装し高度な画像認識や時系列データ処理の開発。</FigureSentences>
                    </FigureExplanation>
                    <FigureExplanation>
                        {breakPoints.mobileWide
                            ?
                                <FigureImgWide src={RecruitFigure5} />
                            :
                                <FigureImg src={RecruitFigure5} />
                        }
                        <FigureSentences>電子回路や通信ネットワークをくみ上げ、上から下まで一気通貫でシステム開発。</FigureSentences>
                    </FigureExplanation>
                </FigureExplanations>
                <Explanation>
                    弊社は特定の技術領域に絞ることなく、バックエンド、フロントエンド、機械学習、通信などプロダクト開発に必要な技術を幅広くエンジニアが習得できるような仕事・タスクを提供し、「本物のエンジニア」の育成を目指します。
                </Explanation>
                <Explanation>
                    最先端の幅広い技術を組みあわせ、様々な業界の顧客に最適なソリューションを提供するシステムの開発を行っていきます。また、それら最先端の技術を各種パッケージとして作り上げていきます。
                </Explanation>
            </Inner>
            <Spacer height="50px" />
            <Img
                fluid={FluidImages("RecruitPhotoMiddle")}
                style={{ width: "100%", height: "250px", objectFit: "cover" }}
            />
        </Outer>
    )
}

export default MobileRecruitTop