import { useStaticQuery, graphql } from "gatsby"

const RecruitImages = (request) => {
    const data = useStaticQuery(graphql`
        query {
            RecruitPhoto0: file(relativePath: { eq: "PC/recruit-photo0.jpg" }) {
                childImageSharp {
                    fixed(width: 980, height: 600) {
                        ...GatsbyImageSharpFixed
                    }
                }
            },
            RecruitPhoto1: file(relativePath: { eq: "PC/recruit-photo1.jpg" }) {
                childImageSharp {
                    fixed(width: 480, height: 608) {
                        ...GatsbyImageSharpFixed
                    }
                }
            },
            RecruitPhoto2: file(relativePath: { eq: "PC/recruit-photo2.jpg" }) {
                childImageSharp {
                    fixed(width: 485, height: 297) {
                        ...GatsbyImageSharpFixed
                    }
                }
            },
            RecruitPhoto3: file(relativePath: { eq: "PC/recruit-photo3.jpg" }) {
                childImageSharp {
                    fixed(width: 485, height: 297) {
                        ...GatsbyImageSharpFixed
                    }
                }
            },
            RecruitPhoto4: file(relativePath: { eq: "PC/recruit-photo4.jpg" }) {
                childImageSharp {
                    fixed(width: 980, height: 600) {
                        ...GatsbyImageSharpFixed
                    }
                }
            },
        }
    `)

    return data[request].childImageSharp.fixed
}

export default RecruitImages
