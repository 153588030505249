import React from "react"
import styled from "styled-components"
import { useBreakpoint } from "gatsby-plugin-breakpoints/BreakpointProvider"

const Outer = styled.div`
    display: flex;
    width: 100%;
    justify-content: center;
    margin: 60px 0px 0px 0px;
`

const Inner = styled.div`
    display: inline-block;
    width: 85%;
    min-width: 280px;
`

const Title = styled.h2`
    font-size: 22px;
    font-weight: 500;
    letter-spacing: 1px;
    margin-bottom: 40px;
`

const Requirements = styled.div`
    width: 100%;
    font-size: 14px;
    font-weight: 400;
    letter-spacing: 0.1px;
    margin-top: 20px;
`

const RequirementsRow = styled.div`
    width: 100%;
    line-height: 20px;
    margin-top: 20px;
`

const RequirementsField = styled.p`
    font-weight: 500;
    font-size: 16px;
    margin-bottom: 5px;
`

const RequirementsData = styled.p`
    font-weight: 400;
`

const RequirementsList = styled.p`
    line-height: 23px;
    font-weight: 400;
    letter-spacing: 0.5px;
    margin: 5px 0px 0px auto;
    font-size: 13px;
`


const RequirementsTable = styled.table`
    width: 100%;
    font-size: 16px;
    font-weight: 400;
    letter-spacing: 0.1px;
`

const RequirementsTableBody = styled.tbody`
    width: 100%;
`

const RequirementsTableRow = styled.tr`
    width: 100%;
    height: 60px;
`

const RequirementsTableField = styled.td`
    width: 25%;
`

const RequirementsTableData = styled.td`
    width: 75%;
`

const RequirementsListWide = styled.p`
    position: relative;
    bottom: 35px;
    width: 75%;
    font-weight: 400;
    letter-spacing: 0.5px;
    margin: 10px 0px 10px auto;
`

const MobileRecruitRequirements = () => {

    const breakPoints = useBreakpoint()

    const TableData = [
        {item: "職種", data: "エンジニア"},
        {item: "給与", data: "経験、スキルに応じて決定"},
        {item: "勤務条件", data: "北九州または福岡オフィスに週2回程度集まれること"},
        {item: "資格", data: "年齢、学歴、国籍、性別問わず全ての方"},
        {item: "試用期間", data: "3ヶ月"},
        {item: ["待遇・", "福利厚生"], data: "正社員"},
    ]

    const ListData = [
        "・国際カンファレンスや展示会への海外、国内出張",
        "・国内リゾートでの開発合宿など",
    ]

    return (
        <Outer>
            <Inner>
                <Title>募集要項</Title>
                {breakPoints.smartphone
                    ?
                        <React.Fragment>
                            <Requirements>
                                {TableData.map((t, index) => (
                                    <RequirementsRow key={`requirementsTableRow${index}`}>
                                        <RequirementsField>{t.item}</RequirementsField>
                                        <RequirementsData>{t.data}</RequirementsData>
                                    </RequirementsRow>
                                ))}
                            </Requirements>
                            {ListData.map((l, index) => (
                                <RequirementsList key={`requirementsList${index}`}>{l}</RequirementsList>
                            ))}
                        </React.Fragment>
                    :
                        <React.Fragment>
                            <RequirementsTable>
                                <RequirementsTableBody>
                                    {TableData.map((t, index) => (
                                        <RequirementsTableRow key={`requirementsTableRow${index}`}>
                                            <RequirementsTableField>{t.item}</RequirementsTableField>
                                            <RequirementsTableData>{t.data}</RequirementsTableData>
                                        </RequirementsTableRow>
                                    ))}
                                </RequirementsTableBody>
                            </RequirementsTable>
                            {ListData.map((l, index) => (
                                <RequirementsListWide key={`requirementsList${index}`}>{l}</RequirementsListWide>
                            ))}
                        </React.Fragment>
                }
            </Inner>
        </Outer>
    )
}

export default MobileRecruitRequirements