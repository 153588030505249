import React from "react"
import styled from "styled-components"
import RecruitImages from "../../Images/RecruitImages"
import Img from "gatsby-image"
import Spacer from "../atoms/Spacer"


const Outer = styled.div`
    display: flex;
    width: 100%;
    justify-content: center;
`

const Inner = styled.div`
    display: inline-block;
    width: 990px;
`

const FlexPhotoWrapper = styled.div`
    display: flex;
`

const FlexPhotoDivider = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-left: 15px;
`

const RecruitPhotos = () => {

    return (
        <Outer>
            <Inner>
                <Spacer height="70px" />
                <Img fixed={RecruitImages("RecruitPhoto0")} />
                <Spacer height="10px" />
                <FlexPhotoWrapper>
                    <Img fixed={RecruitImages("RecruitPhoto1")} />
                    <FlexPhotoDivider>
                        <Img fixed={RecruitImages("RecruitPhoto2")} />
                        <Img fixed={RecruitImages("RecruitPhoto3")} />
                    </FlexPhotoDivider>
                </FlexPhotoWrapper>
                <Spacer height="10px" />
                <Img fixed={RecruitImages("RecruitPhoto4")} />
                <Spacer height="120px" />
            </Inner>
        </Outer>
    )
}

export default RecruitPhotos