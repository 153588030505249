import React from "react"

const RecruitFigureExplanation = (props) => {

    const ExplanationStyles = {
        position: "absolute",
        top: (props.top !== undefined) ? props.top + "px" : null,
        left: (props.left !== undefined) ? props.left + "px" : null,
        right: (props.right !== undefined) ? props.right + "px" : null,
        display: "inline-block",
        fontSize: "14px",
        fontWeight: "400",
        letterSpacing: "1px",
        lineHeight: "28px"
    }

    return <p style={ExplanationStyles}>{props.children}</p>
}

export default RecruitFigureExplanation