import React from "react"
import SEO from "../seo"
import Hero from "../PC/organisms/Hero"
import Header from "../PC/organisms/Header"
import Footer from "../PC/organisms/Footer"
import styled from "styled-components"
import RecruitTop from "../PC/organisms/RecruitTop"
import RecruitRequirements from "../PC/organisms/RecruitRequirements"
import { HorizontalLine } from "../PC/atoms/Decoration"
import Spacer from "../PC/atoms/Spacer"
import RecruitIntroduces from "../PC/organisms/RecruitIntroduces"
import RecruitPhotos from "../PC/organisms/RecruitPhotos"
import { useBreakpoint } from "gatsby-plugin-breakpoints"
import MobileRecruitTop from "../mobile/organisms/MobileRecruitTop"
import MobileRecruitRequirements from "../mobile/organisms/MobileRecruitRequirements"
import MobileRecruitIntroduces from "../mobile/organisms/MobileRecruitIntroduces"
import MobileRecruitPhotos from "../mobile/organisms/MobileRecruitPhotos"
import judgePage from "../judgePage"
import MobileHeader from "../mobile/organisms/MobileHeader"
import MobileFooter from "../mobile/organisms/MobileFooter"
import MobileHero from "../mobile/organisms/MobileHero"

const Template = styled.div`
    position: absolute;
    top: 0%;
    left: 0%;
    height: auto;
    width: 100%;
`

const RecruitSEOData = {
    title: 'リクルート',
    lang: 'jp',
    url: '/recruit',
    description:'QUANDOは小売り、製造、金融等の「人・モノが活動するリアルな現場」の情報を取得し、機械学習やビックデータ分析技術を用いて、リアルタイムに分析・反映・制御を行う技術を開発しています。',
}

const RecruitTemplate = () => {

    const page = judgePage('RECRUIT')
    const breakPoints = useBreakpoint()

    return (
        <Template>
            <SEO
                title={RecruitSEOData.title}
                lang={RecruitSEOData.lang}
                url={RecruitSEOData.url}
                description={RecruitSEOData.description}
            />
            {breakPoints.pc
                ?
                    <React.Fragment>
                        <Hero page={page.name} />
                        <RecruitTop />
                        <RecruitRequirements />
                        <Spacer height="80px" />
                        <HorizontalLine />
                        <Spacer height="35px" />
                        <RecruitIntroduces />
                        <RecruitPhotos />
                        <Footer />
                        <Header page={page.name} />
                    </React.Fragment>
                :
                    null
            }
            {breakPoints.mobile
                ?
                    <React.Fragment>
                        <MobileHero page={page.name} />
                        <MobileRecruitTop />
                        <MobileRecruitRequirements />
                        <MobileRecruitIntroduces />
                        <MobileRecruitPhotos />
                        <MobileFooter />
                        <MobileHeader />
                    </React.Fragment>
                :
                    null
            }
        </Template>
    )
}

export default RecruitTemplate