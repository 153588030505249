import React from "react"
import styled from "styled-components"
import Spacer from "../atoms/Spacer"

const Outer = styled.div`
    display: flex;
    width: 100%;
    justify-content: center;
    margin: 60px 0px 0px 0px;
`

const Inner = styled.div`
    display: inline-block;
    max-width: 990px;
    width: 80%;
`

const Title = styled.h2`
    font-size: 28px;
    font-weight: 400;
    letter-spacing: 1px;
    margin: 40px 0px 40px 0px;
`

const RequirementsTable = styled.table`
    width: 100%;
    font-size: 16px;
    font-weight: 400;
    letter-spacing: 0.1px;
`

const RequirementsTableBody = styled.tbody`
    width: 100%;
`

const RequirementsTableRow = styled.tr`
    width: 100%;
    height: 60px;
`

const RequirementsTableField = styled.td`
    width: 18%;
`

const RequirementsTableData = styled.td`
    width: 82%;
`

const RequirementsList = styled.p`
    position: relative;
    bottom: 35px;
    width: 82%;
    font-weight: 400;
    letter-spacing: 0.5px;
    margin: 10px 0px 10px auto;
`

const RecruitRequirements = () => {

    const TableData = [
        {item: "職種", data: "エンジニア"},
        {item: "給与", data: "経験、スキルに応じて決定"},
        {item: "勤務条件", data: "北九州または福岡オフィスに週2回程度集まれること"},
        {item: "資格", data: "年齢、学歴、国籍、性別問わず全ての方"},
        {item: "試用期間", data: "3ヶ月"},
        {item: "待遇・福利厚生", data: "正社員"},
    ]

    const ListData = [
        "・国際カンファレンスや展示会への海外、国内出張",
        "・国内リゾートでの開発合宿など",
    ]

    return (
        <Outer>
            <Inner>
                <Title>募集要項</Title>
                <RequirementsTable>
                    <RequirementsTableBody>
                        {TableData.map((t, index) => (
                            <RequirementsTableRow key={`requirementsTableRow${index}`}>
                                <RequirementsTableField>{t.item}</RequirementsTableField>
                                <RequirementsTableData>{t.data}</RequirementsTableData>
                            </RequirementsTableRow>
                        ))}
                    </RequirementsTableBody>
                </RequirementsTable>
                {ListData.map((l, index) => (
                    <RequirementsList key={`requirementsList${index}`}>{l}</RequirementsList>
                ))}
                <Spacer height="50px" />
            </Inner>
        </Outer>
    )
}

export default RecruitRequirements