import React from "react"
import styled from "styled-components"
import Img from "gatsby-image"
import { RecruitFigureSVG } from "../../Images/svg"
import RecruitFigureExplanation from "../atoms/RecruitFigureExplanation"
import FluidImages from "../../Images/FluidImages"
import Spacer from "../atoms/Spacer"

const Outer = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    height: auto;
    align-items: center;
`

const Inner = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 990px;
`

const Title = styled.h2`
    text-align: center;
    font-size: 52px;
    font-weight: 700;
    letter-spacing: 9px;
    margin: 50px 0px;
`

const TopSentence = styled.p`
    margin: 5px 0px;
    letter-spacing: 1px;
`

const Explanation = styled.p`
    line-height: 28px;
    letter-spacing: 1px;
`

const RecruitTop = () => {

    return (
        <Outer>
            <Inner>
                <Title>UNLEASH THE POWER OF YOU</Title>
                <TopSentence>我々は特定の型にはめ込むような人材教育は行いません。ひとりひとりが持つ能力や個性は千差万別。</TopSentence>
                <TopSentence>個々人が持つポテンシャルを開放し、自分らしく価値を発揮できる人が育つような環境を提供します。</TopSentence>
                <Spacer height="50px" />
                <RecruitFigureSVG />
                <RecruitFigureExplanation top="460">
                    ReactやRuby on Railsを用いて<br />様々なシステムの基盤やハイブリッドアプリの開発。
                </RecruitFigureExplanation>
                <RecruitFigureExplanation top="660" left="0">
                    RDBMSだけでなく、MongoDBやNeo4jにため込んだ<br />大量のデータから、サービスの競争力を高める情報を導出。
                </RecruitFigureExplanation>
                <RecruitFigureExplanation top="660" right="0">
                    UnityやBlenderによる3D技術や<br />リアルタイム通信技術を組み合わせてアプリケーション開発。
                </RecruitFigureExplanation>
                <RecruitFigureExplanation top="950" left="120">
                    最新のアルゴリズムを実装し<br />高度な画像認識や時系列データ処理の開発。
                </RecruitFigureExplanation>
                <RecruitFigureExplanation top="950" right="150">
                    電子回路や通信ネットワークをくみ上げ、<br />上から下まで一気通貫でシステム開発。
                </RecruitFigureExplanation>
                <Explanation>
                    弊社は特定の技術領域に絞ることなく、バックエンド、フロントエンド、機械学習、通信などプロダクト開発に必要な技術を幅広くエンジニアが習得できるような仕事・タスクを提供し、「本物のエンジニア」の育成を目指します。
                </Explanation>
                <Explanation>
                    最先端の幅広い技術を組みあわせ、様々な業界の顧客に最適なソリューションを提供するシステムの開発を行っていきます。また、それら最先端の技術を各種パッケージとして作り上げていきます。
                </Explanation>
            </Inner>
            <Spacer height="80px" />
            <Img
                fluid={FluidImages("RecruitPhotoMiddle")}
                style={{ width: "100%", height: "600px", objectFit: "cover" }}
            />
        </Outer>
    )
}

export default RecruitTop