import React from "react"
import styled from "styled-components"
import Spacer from "../../PC/atoms/Spacer"
import { useBreakpoint } from "gatsby-plugin-breakpoints/BreakpointProvider"

const Outer = styled.div`
    display: flex;
    width: 100%;
    justify-content: center;
`

const Inner = styled.div`
    display: inline-block;
    min-width: 280px;
    width: 85%;
`

const Title = styled.h2`
    font-size: 22px;
    font-weight: 500;
    letter-spacing: 1px;
    margin: 50px 0px 40px 0px;
`

const LanguageList = styled.p`
    font-weight: 400;
    line-height: 32px;
`

const Table = styled.div`
    width: 100%;
    font-size: 16px;
    font-weight: 400;
    letter-spacing: 0.1px;
`

const TableBody = styled.div`
    width: 100%;
`

const TableRow = styled.div`
    width: 100%;
    line-height: 23px;
`

const TableField = styled.p`
    font-weight: 500;
    font-size: 16px;
    margin-bottom: 5px;
`

const TableData = styled.p`
    margin-bottom: 20px;
    font-weight: 400;
`

const TableWide = styled.table`
    width: 100%;
    font-size: 16px;
    font-weight: 400;
    letter-spacing: 0.1px;
`

const TableBodyWide = styled.tbody`
    width: 100%;
`

const TableRowWide = styled.tr`
    width: 100%;
    height: 60px;
`

const TableFieldWide = styled.td`
    width: 33%;
`

const TableDataWide = styled.td`
    width: 67%;
    line-height: 23px;
`


const Ideality = styled.p`
    line-height: 32px;
    font-weight: 400;
`

const MobileRecruitIntroduces = () => {

    const breakPoints = useBreakpoint()

    const DevEnvironmentsData = [
        {field: "環境", data: "Windows PCもしくはMacを支給"},
        {field: "コード管理", data: "GitHubを利用"},
        {field: "プロジェクト管理", data: "Redmineを使用"},
        {field: "インフラ環境", data: "	クラウドではAWSを、ローカルではVirtualBox/Vagrantにて仮想環境を構築"},
        {field: "コミュニケーションツール", data: "基本はSlackで、会議はAppear.inを使用"},
    ]

    const Idealities = [
        {
            field: "必須スキル・経験",
            data: [
                "・プログラミング言語を用いた開発経験",
                "・今まで経験したことのない言語や技術領域でも学習することができる柔軟さ"
            ]
        },
        {
            field: "歓迎スキル・経験",
            data: [
                "・チーム開発経験",
                "・プロジェクトマネジメントの経験（プロジェクトマネージャー希望の場合）",
                "・WEBシステムの開発経験",
                "・機械学習を用いた開発経験や基礎知識",
                "・組み込みシステムの開発経験や基礎知識",
                "・スマホアプリの開発経験",
                "・アジャイル開発の経験",
                "・統計学に関する知識",
                "・3Dモデリングの開発経験",
            ]
        },
    ]

    return (
        <React.Fragment>
            <Outer>
                <Inner>
                    <Title>主要言語</Title>
                    <LanguageList>・HTML, CSS, Javascript, Ruby, Python, C#</LanguageList>
                    <LanguageList>・React, Ruby on Rails, Node.js, Unity</LanguageList>
                    <LanguageList>・Apache, Nginx, PostgreSQL, MongoDB, Neo4j</LanguageList>
                </Inner>
            </Outer>
            <Outer>
                <Inner>
                    <Title>開発環境</Title>
                    {breakPoints.smartphone
                        ?
                            <Table>
                                <TableBody>
                                    {DevEnvironmentsData.map((d, index) => (
                                        <TableRow key={`DevEnvTable${index}`}>
                                            <TableField>{d.field}</TableField>
                                            <TableData>{d.data}</TableData>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        :
                            <TableWide>
                                <TableBodyWide>
                                    {DevEnvironmentsData.map((d, index) => (
                                        <TableRowWide key={`DevEnvTable${index}`}>
                                            <TableFieldWide>{d.field}</TableFieldWide>
                                            <TableDataWide>{d.data}</TableDataWide>
                                        </TableRowWide>
                                    ))}
                                </TableBodyWide>
                            </TableWide>
                    }
                </Inner>
            </Outer>
            <Outer>
                <Inner>
                    <Title>求める人材像</Title>
                    {breakPoints.smartphone
                        ?
                            <React.Fragment>
                                <Table>
                                    <IdealitiesTableBody idealities={Idealities.slice(0, 1)} />
                                </Table>
                                <Spacer height="10px" />
                                <Table>
                                    <IdealitiesTableBody idealities={Idealities.slice(1, 2)} />
                                </Table>
                            </React.Fragment>
                        :
                            <React.Fragment>
                                <TableWide>
                                    <IdealitiesTableBody idealities={Idealities.slice(0, 1)} />
                                </TableWide>
                                <Spacer height="10px" />
                                <TableWide>
                                    <IdealitiesTableBody idealities={Idealities.slice(1, 2)} />
                                </TableWide>
                            </React.Fragment>
                    }
                </Inner>
            </Outer>
        </React.Fragment>
    )
}

const IdealitiesTableBody = (props) => {

    const breakPoints = useBreakpoint()

    return (
        <React.Fragment>
            {breakPoints.smartphone
                ?
                    <TableRow>
                        <TableField>{props.idealities[0].field}</TableField>
                            {props.idealities[0].data.map((d, index) => (<Ideality key={`Ideality${index}`}>{d}</Ideality>))}
                    </TableRow>
                :
                    <TableBodyWide>
                        <TableRowWide>
                            <TableFieldWide>{props.idealities[0].field}</TableFieldWide>
                            <TableDataWide>
                                {props.idealities[0].data.map((d, index) => (<Ideality key={`Ideality${index}`}>{d}</Ideality>))}
                            </TableDataWide>
                        </TableRowWide>
                    </TableBodyWide>
            }
        </React.Fragment>
    )
}

export default MobileRecruitIntroduces