import React from "react"
import styled from "styled-components"
import Img from "gatsby-image"
import Spacer from "../../PC/atoms/Spacer"
import MobileFluidImages from "../../Images/MobileFluidImages"


const Outer = styled.div`
    display: flex;
    width: 100%;
    justify-content: center;
`

const Inner = styled.div`
    display: inline-block;
    width: 85%;
    min-width: 280px;
`

const FlexPhotoWrapper = styled.div`
    display: flex;
    width: 100%;
    margin: 7px 0;
`

const FlexPhotoDivider = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 49%;
    margin: 0 0 0 auto ;
`

const MobileRecruitPhotos = () => {

    return (
        <Outer>
            <Inner>
                <Spacer height="70px" />
                <Img
                    fluid={MobileFluidImages("MobileRecruitPhoto0")}
                    style={{　width: "100%"　}}
                />
                <FlexPhotoWrapper>
                    <Img
                        fluid={MobileFluidImages("MobileRecruitPhoto1")}
                        style={{ width: "49%" }}
                    />
                    <FlexPhotoDivider>
                        <Img
                            fluid={MobileFluidImages("MobileRecruitPhoto2")}
                            style={{ width: "100%" }}
                        />
                        <Img
                            fluid={MobileFluidImages("MobileRecruitPhoto3")}
                            style={{ width: "100%" }}
                        />
                    </FlexPhotoDivider>
                </FlexPhotoWrapper>
                <Img
                    fluid={MobileFluidImages("MobileRecruitPhoto4")}
                    style={{ width: "100%" }}
                />
                <Spacer height="70px" />
            </Inner>
        </Outer>
    )
}

export default MobileRecruitPhotos